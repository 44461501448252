import { useMemo, useState } from "react";
import { Dialog, Form, NumberPicker } from "@alifd/next";
import { useMemoizedFn } from "ahooks";
import { IDistributeItemInfo } from "../index";

const defaultValue: IDistributeItemInfo = {
  id: 0,
  confId: 0,
  confName: "",
  code: "",
  codeWeight: 0,
  useCode: false,
  inviteId: "",
  inviteIdWeight: 0,
  useInviteId: false,
  status: false,
  mobile: "",
  activityAOV1: 0,
  activityAOV2: 0,
  currentDayActivityAOV1: 0,
  currentDayActivityAOV2: 0,
  remark: "",
  isHide: false,
  isActivity3: false,
  cookie2: "",
  isJump: false,
  sid: ""
};

export default ({
  onChange
}: {
  onChange: (value: IDistributeItemInfo, callback?: () => void) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editState, setEditState] = useState<IDistributeItemInfo>(defaultValue);

  const showEditForm = useMemoizedFn(() => {
    setVisible(true);
  });

  const hideEditForm = useMemoizedFn(() => {
    setVisible(false);
  });

  const placeholder = useMemo(
    () => (
      <Dialog
        visible={visible}
        closeable={false}
        onCancel={hideEditForm}
        onOk={() => {
          onChange(editState, () => {
            setLoading(false);
            hideEditForm();
          });
        }}
        okProps={{
          loading
        }}
      >
        <div style={{ width: "200px" }}>
          <Form value={editState} onChange={setEditState}>
            <Form.Item name="inviteIdWeight" label="活动1 inviteId分发权重">
              <NumberPicker min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="codeWeight" label="活动2 code分发权重">
              <NumberPicker min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </div>
      </Dialog>
    ),
    [onChange, hideEditForm, loading, visible, editState]
  );

  return {
    placeholder,
    showEditForm,
    hideEditForm,
    initEditFormState: setEditState
  };
};
