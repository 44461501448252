import { NumberPicker } from "@alifd/next";
import { useState } from "react";
import EditorBox from "./EditorBox";

const NumberPickerEditorBox = ({
  defaultValue,
  onChange
}: {
  defaultValue: number;
  onChange: (value: number, successCallback?: () => void) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue ?? 0);

  return (
    <div className="TableEdit">
      <span style={{ marginRight: "4px" }}>{value}</span>
      <EditorBox
        visible={visible}
        loading={loading}
        onVisibleChange={setVisible}
        onOk={() => {
          setLoading(true);
          onChange(value, () => {
            setLoading(false);
            setVisible(false);
          });
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <NumberPicker
          value={value}
          min={0}
          style={{ width: "100%" }}
          onChange={setValue}
        />
      </EditorBox>
    </div>
  );
};

export default NumberPickerEditorBox;
