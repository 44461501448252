import { Button } from "@alifd/next";

export default () => {
  return (
    <div style={{ marginBottom: "24px" }}>
      <Button.Group>
        <Button style={{ marginRight: "16px" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://remote-sms-2gry7quq75d8f6cb-1257170569.ap-guangzhou.app.tcloudbase.com/"
          >
            短信平台
          </a>
        </Button>
        <Button style={{ marginRight: "16px" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://ele-login.tools.pmlife.work/"
          >
            饿了么自助登录
          </a>
        </Button>
      </Button.Group>
    </div>
  );
};
