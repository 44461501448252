import { useMemo } from "react";
import { Table, Switch, Message } from "@alifd/next";
import { IDistributeItemInfo } from "../index";
import axiosInst from "../../../services/axios";
import { useRequest } from "ahooks";

export default ({ onRefresh }: { onRefresh?: () => void }) => {
  const { run: submitUpdateStatus } = useRequest(
    async (id: number, activity3: boolean) => {
      const res = await axiosInst.post(
        "/api/ele_reward/auto_distribution/flag",
        {
          id,
          activity3
        }
      );
      return res.data;
    },
    {
      manual: true,
      onSuccess: () => {
        Message.success({
          title: "更新成功"
        });
        onRefresh?.();
      },
      onError: (err) => {
        Message.warning({
          content: err
        });
      }
    }
  );

  const tableColumn = useMemo(
    () => (
      <Table.Column title="活动3" align="center">
        <Table.Column
          title="是否开启"
          align="center"
          dataIndex="isActivity3"
          width={100}
          cell={(val: boolean, itemIdx: number, item: IDistributeItemInfo) => (
            <Switch
              size="small"
              checked={val}
              onChange={(checked) => {
                submitUpdateStatus(item.id, checked);
              }}
            />
          )}
        />
      </Table.Column>
    ),
    [submitUpdateStatus]
  );

  return {
    tableColumn
  };
};
