import { useMemo } from "react";
import { Table } from "@alifd/next";
import { IDistributeItemInfo } from "../index";
import NumberPickerEditBox from "../../../components/NumberPickerEditorBox";

export default ({
  onChange
}: {
  onChange: (value: IDistributeItemInfo, callback?: () => void) => void;
}) => {
  const tableColumn = useMemo(() => {
    return (
      <Table.Column title="活动2（code）" align="center">
        <Table.Column dataIndex="code" title="口令" width={150} />
        <Table.Column
          dataIndex="activityAOV2"
          title="均单(30分钟)"
          width={110}
          cell={(val: number) => `￥${val ?? 0}`}
        />
        <Table.Column
          dataIndex="currentDayActivityAOV2"
          title="均单(全天)"
          width={110}
          cell={(val: number) => `￥${val ?? 0}`}
        />
        <Table.Column
          dataIndex="useCode"
          title="均单最高选中"
          align="center"
          width={110}
          cell={(val: boolean) => (val ? "✅️" : "")}
        />
        <Table.Column
          dataIndex="codeWeight"
          title="分发权重"
          width={90}
          cell={(val: number, itemIdx: number, item: IDistributeItemInfo) => {
            return (
              <NumberPickerEditBox
                defaultValue={val}
                onChange={(val, callback) => {
                  onChange({ ...item, codeWeight: val }, callback);
                }}
              />
            );
          }}
        />
      </Table.Column>
    );
  }, [onChange]);

  return {
    tableColumn
  };
};
