import { Balloon, Button, Icon } from "@alifd/next";
import { PropsWithChildren } from "react";

const EditBox = ({
  children,
  visible,
  loading,
  onVisibleChange,
  onOk,
  onCancel
}: PropsWithChildren<{
  visible: boolean;
  loading: boolean;
  onVisibleChange: (value: boolean) => void;
  onOk: () => void;
  onCancel: () => void;
}>) => {
  return (
    <Balloon
      visible={visible}
      closable={false}
      triggerType="click"
      needAdjust
      trigger={<Icon className="TableEdiBtn" size="xs" type="edit" />}
      onVisibleChange={onVisibleChange}
    >
      <div style={{ minWidth: "200px", padding: "0px" }}>
        <div style={{ padding: "4px 0" }}>编辑</div>
        {children}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px"
          }}
        >
          <Button
            loading={loading}
            type="normal"
            style={{ marginRight: "8px" }}
            onClick={onOk}
          >
            确认
          </Button>
          <Button type="normal" onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </Balloon>
  );
};

export default EditBox;
