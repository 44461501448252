import { Table, DatePicker2, Select, Message } from "@alifd/next";
import { useFusionTable } from "ahooks";
import { useState } from "react";
import axiosInst from "../../services/axios";
import dayjs from "dayjs";

interface IDataInfo {
  id: number;
  confId: string;
  confName: string;
  income: number;
  orderIncome: number;
  orderNum: number;
  avgOrderIncome: number;
  avgPackageIncome: number;
}

const activityOptions = [
  {
    label: "活动1",
    value: 1
  },
  {
    label: "活动2",
    value: 2
  },
  {
    label: "活动3",
    value: 3
  }
];

const sortCode: Record<string, number> = {
  income: 1
};

export default () => {
  const [date, setDate] = useState(dayjs().valueOf());
  const [activity, setActivity] = useState(1);
  const [sortInfo, setSortInfo] = useState({
    code: 0,
    isAsc: false
  });

  const { tableProps } = useFusionTable(
    async () => {
      const res = await axiosInst.post<{ data: { list: IDataInfo[] } }>(
        "/api/ele_reward/auto_distribution/data_view",
        {
          accountId: 1,
          actFlag: activity,
          date,
          sortFlag: sortInfo.code,
          isAsc: sortInfo.isAsc
        }
      );
      const { list } = res.data.data;
      const summaryIncome = list.reduce(
        (preRes, item) => preRes + item.income,
        0
      );
      const summaryOrderIncome = list.reduce(
        (preRes, item) => preRes + item.orderIncome,
        0
      );
      const summaryOrderNum = list.reduce(
        (preRes, item) => preRes + item.orderNum,
        0
      );
      const summaryAllOrderNum = list.reduce((preRes, item) => {
        if (!item.avgPackageIncome) return preRes;
        return preRes + item.orderIncome / item.avgPackageIncome;
      }, 0);
      const summaryItem: IDataInfo = {
        id: 0,
        confId: "总计",
        confName: "总计",
        income: summaryIncome,
        orderNum: summaryOrderNum,
        orderIncome: summaryOrderIncome,
        avgOrderIncome:
          summaryOrderIncome / (summaryOrderNum ? summaryOrderNum : 1),
        avgPackageIncome:
          summaryOrderIncome / (summaryAllOrderNum ? summaryAllOrderNum : 1)
      };
      return {
        page: 0,
        total: list.length,
        pageSize: list.length,
        list: list.concat([summaryItem])
      };
    },
    {
      refreshDeps: [date, activity, sortInfo]
    }
  );

  return (
    <div style={{ padding: "12px" }}>
      <Message type="notice">
        <ul>
          <li>• 均单收入 = 订单佣金 / 订单数量 </li>
          <li>• 均包收入 = 订单佣金 / (订单数量 + 在路上数量）</li>
        </ul>
      </Message>
      <div style={{ height: "24px" }} />
      <div style={{ padding: "0 0 24px 0" }}>
        <DatePicker2
          style={{ marginRight: "12px" }}
          hasClear={false}
          value={date}
          onChange={(val) => {
            setDate(val.valueOf());
          }}
        />
        <Select
          value={activity}
          dataSource={activityOptions}
          onChange={(val) => {
            setActivity(val);
          }}
        />
      </div>
      <div style={{ overflow: "auto" }}>
        <Table
          {...tableProps}
          cellProps={(rowIndex, colIndex) => {
            if (
              rowIndex === tableProps.dataSource.length - 1 &&
              colIndex === 0
            ) {
              return {
                colSpan: 2
              };
            }
          }}
          onSort={(dataIndex, order) => {
            setSortInfo({
              code: sortCode[dataIndex] ?? 0,
              isAsc: order === "asc"
            });
          }}
          style={{ minWidth: "500px" }}
        >
          <Table.Column dataIndex="confId" title="ID" />
          <Table.Column dataIndex="confName" title="账号名称" />
          <Table.Column
            dataIndex="income"
            title="收入"
            sortable
            cell={(val: number) => `￥${val.toFixed(2)}`}
          />
          <Table.Column
            dataIndex="orderIncome"
            title="订单收入"
            sortable
            cell={(val: number) => `￥${val.toFixed(2)}`}
          />
          <Table.Column dataIndex="orderNum" title="订单数" />
          <Table.Column
            dataIndex="avgOrderIncome"
            title="均单收入"
            cell={(val: number) => `￥${val.toFixed(2)}`}
          />
          <Table.Column
            dataIndex="avgPackageIncome"
            title="均包收入"
            cell={(val: number) => `￥${val.toFixed(2)}`}
          />
        </Table>
      </div>
    </div>
  );
};
