import { useFusionTable, useRequest } from "ahooks";
import axiosInst from "../../services/axios";

import useEditForm from "./hooks/useEditForm";
import useActivityOne from "./hooks/useActivityOne";
import useActivityTwo from "./hooks/useActivityTwo";
import useActivityThree from "./hooks/useActivityThree";

import { Button, Message, Table, Divider, Switch } from "@alifd/next";

import TextEditBox from "../../components/TextEditorBox";
import Tips from "./components/Tips/index";
import ButtonGroups from "./components/ButtonGroups/index";
import LinkButton from "./components/LinkButton/index";

import "../../styles.css";

export interface IDistributeItemInfo {
  id: number;
  confId: number;
  confName: string;
  code: string;
  codeWeight: number;
  useCode: boolean;
  inviteId: string;
  inviteIdWeight: number;
  useInviteId: boolean;
  status: boolean;
  mobile: string;
  activityAOV1: number;
  activityAOV2: number;
  currentDayActivityAOV1: number;
  currentDayActivityAOV2: number;
  remark: string;
  isHide: boolean;
  isActivity3: boolean;
  cookie2: string;
  isJump: boolean;
  sid: string;
}

function getPageCodeUrl(confId: string) {
  return `http://a01.act-20211020.pmlife.work/n/${confId}/index.html`;
}

function getPageActivityOneUrl(cookie2: string, sid: string) {
  return `http://act-20220112.pmlife.work/?cookie2=${cookie2}&sid=${sid}#/v1`;
}

function getPageActivityTwoUrl(cookie2: string, sid: string) {
  return `http://act-20220112.pmlife.work/?cookie2=${cookie2}&sid=${sid}#/v2`;
}

function getPageActivityThreeUrl(cookie2: string, sid: string) {
  return `http://act-20220112.pmlife.work/?cookie2=${cookie2}&sid=${sid}#/v3`;
}

export default function Distribute() {
  const { refresh, tableProps } = useFusionTable(async () => {
    const res = await axiosInst.get<{ data: { list: IDistributeItemInfo[] } }>(
      "/api/ele_reward/auto_distribution_conf",
      {
        params: {
          accountId: 1
        }
      }
    );
    const { list } = res.data.data;
    return {
      page: 0,
      total: list.length,
      pageSize: list.length,
      list: list.filter((item) => !item.isHide)
    };
  });

  const { run: submitUpdate } = useRequest(
    async (value: IDistributeItemInfo, callback?: () => void) => {
      const res = await axiosInst.post(
        "/api/ele_reward/auto_distribution_conf",
        {
          ...value
        }
      );
      callback?.();
      return res.data;
    },
    {
      manual: true,
      onSuccess: () => {
        Message.success({
          title: "更新成功"
        });
        refresh();
      },
      onError: (err) => {
        Message.warning({
          content: err
        });
      }
    }
  );

  const { run: submitUpdateRemark } = useRequest(
    async (value: IDistributeItemInfo, callback?: () => void) => {
      const res = await axiosInst.post(
        "/api/ele_reward/auto_distribution_remark",
        {
          ...value
        }
      );
      callback?.();
      return res.data;
    },
    {
      manual: true,
      onSuccess: () => {
        Message.success({
          title: "更新成功"
        });
        refresh();
      },
      onError: (err) => {
        Message.warning({
          content: err
        });
      }
    }
  );

  const { run: submitUpdateIOSLinkStatus } = useRequest(
    async (id: number, jump: boolean) => {
      const res = await axiosInst.post(
        "/api/ele_reward/auto_distribution/jump",
        {
          id,
          jump
        }
      );
      return res.data;
    },
    {
      manual: true,
      onSuccess: () => {
        Message.success({
          title: "更新成功"
        });
        refresh();
      },
      onError: (err) => {
        Message.warning({
          content: err
        });
      }
    }
  );

  const {
    placeholder: EditFormPlaceholder,
    initEditFormState,
    showEditForm
  } = useEditForm({
    onChange: submitUpdate
  });

  const { tableColumn: ActivityOneColumn } = useActivityOne({
    onChange: submitUpdate
  });

  const { tableColumn: ActivityTwoColumn } = useActivityTwo({
    onChange: submitUpdate
  });

  const { tableColumn: ActivityThreeColumn } = useActivityThree({
    onRefresh: refresh
  });

  return (
    <div style={{ padding: "12px" }}>
      <Tips />
      <ButtonGroups />

      <div style={{ overflow: "auto" }}>
        <Table {...tableProps} style={{ minWidth: "2000px" }}>
          <Table.Column dataIndex="confId" title="ID" />
          <Table.Column
            dataIndex="confName"
            title="账号信息"
            cell={(val: string, itemIdx: number, item: IDistributeItemInfo) => (
              <div
                style={{ whiteSpace: "pre-line" }}
              >{`${val}\n${item.mobile}`}</div>
            )}
          />
          <Table.Column
            title="备注"
            dataIndex="remark"
            width={120}
            cell={(val: string, itemIdx: number, item: IDistributeItemInfo) => (
              <TextEditBox
                defaultValue={val}
                onChange={(val, callback) => {
                  submitUpdateRemark({ ...item, remark: val }, callback);
                }}
              />
            )}
          />

          {ActivityOneColumn}

          {ActivityTwoColumn}

          {ActivityThreeColumn}

          <Table.Column
            dataIndex="isJump"
            title="是否开启iOS跳转"
            align="center"
            width={130}
            cell={(
              val: boolean,
              itemIdx: number,
              item: IDistributeItemInfo
            ) => (
              <Switch
                size="small"
                checked={val}
                onChange={(checked) => {
                  submitUpdateIOSLinkStatus(item.id, checked);
                }}
              />
            )}
          />

          <Table.Column
            dataIndex="status"
            title="是否在线"
            align="center"
            cell={(val: boolean) => (val ? "✅" : "")}
          />

          <Table.Column
            title="操作"
            cell={(val: any, itemIdx: number, item: any) => {
              return (
                <div>
                  <Button
                    text
                    type="primary"
                    onClick={() => {
                      initEditFormState(item);
                      showEditForm();
                    }}
                  >
                    编辑
                  </Button>
                  <Divider direction="ver" />
                  <LinkButton
                    text="打开code页"
                    anchorProps={{ href: getPageCodeUrl(item.confId) }}
                  />
                  <Divider direction="ver" />
                  <LinkButton
                    text="活动1"
                    anchorProps={{
                      href: getPageActivityOneUrl(item.cookie2, item.sid)
                    }}
                  />
                  <Divider direction="ver" />
                  <LinkButton
                    text="活动2"
                    anchorProps={{
                      href: getPageActivityTwoUrl(item.cookie2, item.sid)
                    }}
                  />
                  <Divider direction="ver" />
                  <LinkButton
                    text="活动3"
                    anchorProps={{
                      href: getPageActivityThreeUrl(item.cookie2, item.sid)
                    }}
                  />
                </div>
              );
            }}
          />
        </Table>
      </div>
      {EditFormPlaceholder}
    </div>
  );
}
