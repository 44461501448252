import { Message } from "@alifd/next";

export default () => {
  return (
    <>
      <Message type="notice">
        <ul>
          <li>• 没有code的不参与code均单最高选中</li>
          <li>• 没有inviteId的不参与inviteId均单最高选中</li>
          <li>• 均单最高选中都是依据均单(30分钟)数值 </li>
          <li>• 分发权重为0不参与分发</li>
          <li>• 暂时仅支持编辑分发权重、备注，其余请到管理后台更新</li>
        </ul>
      </Message>
      <div style={{ height: "24px" }} />
      <Message type="notice">
        <ul>
          <li>② 自动分发饿了么赏金code</li>
          <li>
            {`https://i.isairdrop.top/ekld01 =>
            http://【a01】.act-20211020.pmlife.work`}
          </li>
          <li>
            {`https://i.isairdrop.top/ekld02 =>
            http://【a02】.act-20211020.pmlife.work`}
          </li>
          <li>...</li>
          <li>
            {`https://i.isairdrop.top/ekld03 =>
            http://【a09】.act-20211020.pmlife.work`}
          </li>
        </ul>
      </Message>
      <div style={{ height: "24px" }} />
    </>
  );
};
