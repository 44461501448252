import { Input } from "@alifd/next";
import { useState } from "react";
import EditorBox from "./EditorBox";

const TextEditorBox = ({
  defaultValue,
  onChange
}: {
  defaultValue: string;
  onChange: (value: string, successCallback?: () => void) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(defaultValue ?? "");

  return (
    <div className="TableEdit">
      <span style={{ marginRight: "4px" }}>{value}</span>
      <EditorBox
        visible={visible}
        loading={loading}
        onVisibleChange={setVisible}
        onOk={() => {
          setLoading(true);
          onChange(value, () => {
            setLoading(false);
            setVisible(false);
          });
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Input value={value} style={{ width: "100%" }} onChange={setValue} />
      </EditorBox>
    </div>
  );
};

export default TextEditorBox;
