import { Tab } from "@alifd/next";
import { useState } from "react";
import Distribute from "./page/Distribute";
import Data from "./page/Data";

export default function App() {
  const [activeKey, setActiveKey] = useState("account");
  return (
    <Tab activeKey={activeKey} onChange={(key) => setActiveKey(String(key))}>
      <Tab.Item title="账号管理" key="account">
        <Distribute />
      </Tab.Item>
      <Tab.Item title="数据统计" key="data">
        <Data />
      </Tab.Item>
    </Tab>
  );
}
