import { Button } from "@alifd/next";
import { ButtonProps } from "@alifd/next/types/button";
import { AnchorHTMLAttributes } from "react";

export default ({
  buttonProps,
  anchorProps,
  text
}: {
  buttonProps?: ButtonProps;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
  text?: string;
}) => {
  return (
    <Button text type="primary" {...buttonProps}>
      <a rel="noreferrer" target="_blank" {...anchorProps}>
        {text || "链接"}
      </a>
    </Button>
  );
};
